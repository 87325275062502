import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import MobileMenu from "./mobile-menu"
import Footer from "./footer"
import "./layout.css"

const Layout = props => {
  // Check if Title has been set
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }

      contentfulHomepage(id: { eq: "4391cc10-a551-5b94-8e58-5cdc5e937532" }) {
        musicImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 3000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        videoImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 3000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        liveImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 3000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        projectImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 3000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }

        aboutImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 3000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        contactImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 3000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  var headerImage =
    data.contentfulHomepage.contactImage.localFile.childImageSharp.fluid
  if (props === undefined) {
    props.title = ""
  }
  switch (props.title) {
    case "Music":
      headerImage =
        data.contentfulHomepage.musicImage.localFile.childImageSharp.fluid
      break
    case "Video":
      headerImage =
        data.contentfulHomepage.videoImage.localFile.childImageSharp.fluid
      break
    case "Projects":
      headerImage =
        data.contentfulHomepage.projectImage.localFile.childImageSharp.fluid
      break

    case "Live":
      headerImage =
        data.contentfulHomepage.liveImage.localFile.childImageSharp.fluid
      break
    case "About":
      headerImage =
        data.contentfulHomepage.aboutImage.localFile.childImageSharp.fluid
      break
    case "Contact":
      headerImage =
        data.contentfulHomepage.contactImage.localFile.childImageSharp.fluid
      break
    default:
      headerImage =
        data.contentfulHomepage.contactImage.localFile.childImageSharp.fluid
      break
  }
  return (
    <>
      <header>
        <MobileMenu />
        <div className="wallpaper fade-in">
          <Img
            fluid={{ ...headerImage, aspectRatio: 2 / 2 }}
            className="backdrop-mobile"
          />
          <Img
            fluid={{ ...headerImage, aspectRatio: 2 / 1 }}
            className="backdrop"
            imgStyle={props.title !== "Video" && { objectPosition: "0 20%" }}
          />

          <h1>{props.title}</h1>
        </div>
      </header>
      <main>{props.children}</main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
